import React from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import H3 from '/src/components/library/H3'
import Body from '/src/components/library/Body'

const Description = ({title1, title2, data}) => {
    return (
        <StyledDescription>
            <Wrapper>
                <TitleWrapper>
                    <SizeWrapper>
                        <H2>{title1}</H2>
                        {title2 &&
                            <H2>{title2}</H2>
                        }
                    </SizeWrapper>
                </TitleWrapper>
                <BodyWrapper>
                    {
                        data.map((d, i) => {
                            return (
                                <Elem key={i}>
                                    {d.Title &&
                                        <H3>{d.Title}</H3>
                                    }
                                    <Body>{d.Description}</Body>
                                </Elem>
                            )
                        })
                    }
                </BodyWrapper>
            </Wrapper>
        </StyledDescription>
    )
}

export default Description

const Wrapper = styled.div`
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  //padding: 10rem 2.4rem;
  padding: 10rem 2.4rem 4rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding: 6rem 2.4rem;
    flex-direction: column;
    gap: 1.4rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 2.6rem 2.4rem;
  }
`

const StyledDescription = styled.div`
  // padding: 6rem 12rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
    gap: 1.4rem;
    // padding: 6rem 3.2rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    // padding: 2.6rem 1.6rem;
  }
`

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`

const SizeWrapper = styled.div`
  width: 80%;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    width: 100%;
  }
`

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
`

const Elem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`
