import React from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import H3 from '/src/components/library/H3'
import Body from '/src/components/library/Body'

import bg from '/static/images/core-values/bg.jpg'

const CoreValues = ({datas}) => {
    let sortedList = datas.core_values_list.sort(function(a, b){return a.position-b.position})
    let leftList = sortedList.slice(0, 3)
    let rightList = sortedList.slice(3, 6)

    return (
        <StyledCoreValues>
            <WrapperBg bg={bg}>
                <Wrapper>
                    <H2>{datas.core_values}</H2>
                    <Container>
                        <Left>
                            {
                                leftList.map((elem) => {
                                    return (
                                        <Content>
                                            <H3>{elem.title}</H3>
                                            <Body>{elem.description}</Body>
                                        </Content>
                                    )
                                })
                            }
                        </Left>
                        <Right>
                            {
                                rightList.map((elem) => {
                                    return (
                                        <Content>
                                            <H3>{elem.title}</H3>
                                            <Body>{elem.description}</Body>
                                        </Content>
                                    )
                                })
                            }
                        </Right>
                    </Container>
                </Wrapper>
            </WrapperBg>
        </StyledCoreValues>
    )
}

export default CoreValues

const Wrapper = styled.div`

  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 7.5rem 2.4rem;

  @media ${({theme}) => theme.breakpoints.large_reverse} {
    padding: 5rem 2.4rem;
  }
`

const WrapperBg = styled.div`
  max-width: ${({theme}) => theme.layout.xxxLargeScreen};
  background-image: url(${({bg}) => bg});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 5rem;
  }

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    // padding: 5rem 3.2rem;
    background-position: left;
    h2 {
      margin-bottom: 4rem;
    }
  }
`

const StyledCoreValues = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
    gap: 4rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    gap: 3.2rem;
  }

`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    gap: 3.2rem;
  }
`

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    gap: 3.2rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  p {
    max-width: 44rem;
  }

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    p {
      max-width: unset;
    }
  }

`
