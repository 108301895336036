import React, {useState} from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import H4 from '/src/components/library/H4'
import Body from '/src/components/library/Body'
import ModalLeader from "../modal-leader/ModalLeader";

const Leadership = ({datas}) => {
    const [modalState, setModalState] = useState(false)
    const [elem, setElem] = useState(null)

    return (
        <>
            {modalState &&
                <ModalLeader data={elem} modalState={modalState} setModalState={setModalState}/>
            }
            <StyledLeadership>
                <Wrapper>
                    <HeadTitleWrapper>
                        <H2>Executive Leadership Team</H2>
                    </HeadTitleWrapper>
                    <Leaders>
                        {
                            datas.edges.map((d, key) => {
                                return (
                                    <Leader key={key}>
                                        <Image img={`${process.env.GATSBY_STRAPI_API_URL}${d.node.photo.url}`}
                                               onClick={() => {
                                                   setElem(d)
                                                   setModalState(true)
                                               }}/>
                                        <NameWrapper>
                                            <H4>{d.node.name}</H4>
                                        </NameWrapper>
                                        <PositionWrapper>
                                            <Body>{d.node.position}</Body>
                                        </PositionWrapper>
                                    </Leader>
                                )
                            })
                        }
                    </Leaders>
                </Wrapper>
            </StyledLeadership>
        </>
    )
}

export default Leadership

const Wrapper = styled.div`

  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0 2.4rem 14rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.large_reverse} {
    padding: 0 2.4rem 5rem 2.4rem;
  }
`

const StyledLeadership = styled.div`
  display: flex;
  justify-content: center;

  @media ${({theme}) => theme.breakpoints.mobile} {
    display: none;
  }
`

const Leaders = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  gap: 8rem;
  width: 100%;
  @media ${({theme}) => theme.breakpoints.large_reverse} {
    //grid-template-columns: repeat(2, minmax(21.6rem, 35rem));
    gap: 4rem;
  }
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    gap: 1.4rem;
    //grid-template-columns: repeat(1, minmax(21.6rem, 35rem));
  }
`

const HeadTitleWrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 5rem;
`

const Leader = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`

const Image = styled.div`
  border-radius: 1.2rem;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: calc(100vw * 0.27);

  &:hover {
    animation: loaderAnimF .5s cubic-bezier(.27, .4, .68, 1.16) alternate forwards;
  }

  &:not(:hover) {
    animation: loaderAnimB 0.2s linear alternate backwards;
  }

  @keyframes loaderAnimF {
    to {
      opacity: 1;
      transform: scale3d(1.05, 1.05, 1);
    }
  }

  @keyframes loaderAnimB {
    from {
      opacity: 1;
      transform: scale3d(1.05, 1.05, 1);
    }
    to {
      transform: scale(1, 1, 1);
    }
  }

`

const NameWrapper = styled.div`
`

const PositionWrapper = styled.div`
  opacity: .5;
`
