import {graphql, useStaticQuery} from "gatsby";
import React from 'react'

import Layout from '/src/components/layout/en/Layout'

import Seo from '/src/components/seo/index'

// import bg from "../../static/images/hero/image/about-us.jpg";

import Description from "../components/description/Description";
import HeroSmall from "../components/hero-small/HeroSmall.js";
import ZPatternFlat from "../components/z-pattern-flat/ZPatternFlat";
import Leadership from "../components/leadership/Leadership";
import LeadershipMobile from "../components/leadership/LeadershipMobile";
import CoreValues from "../components/core-values/CoreValues";
import Checkerboard from "../components/checkerboard/Checkerboard";

const descriptionData1 = {
    title: 'Al Hail Holding —',
    title2: 'On-Point Investment',
    description: [
        {
            title: 'Diversification to Power the Economy',
            subtitle: 'Al Hail Holding has grown into a leading investment business in the UAE with strategically and judiciously planned investments across sectors like finance, real estate and energy, among others. Our diversified investment approach is backed by a network of seasoned analysts and integrated data systems to leverage our sharp focus in spotting business potential and managing risks while contributing to the overall diversification of the economy, and generating a wide range of employment opportunities in the country.',
        },
        {
            title: 'Gateway for Businesses Into the UAE Market',
            subtitle: 'Utilizing business expertise, financial capabilities, and strategic partnerships, Al Hail Holding identifies potential business opportunities and grows them into robust and stable establishments. Years of experience combined with incisive decisions give us the edge to capitalize on the market\'s opportunities and meet its toughest challenges. From joint initiatives between government and private sectors to fostering relationships with top-tier conglomerates, Al Hail Holding is a fertile gateway to local and international business opportunities in the UAE.',
        },
        {
            title: 'Transparency to Build Trust',
            subtitle: 'Keeping our partners and stakeholders aware of our investment activities is of utmost importance. Al Hail Holding consistently adheres to the highest ethical standards in the way we conduct our business by promoting and conforming to the corporate governance best practices and pursuing our investment objectives in the larger social, regulatory and market environment of the UAE.',
        },
    ],
}

const descriptionData2 = {
    title: 'Corporate',
    title2: '& Social Responsibilities',
    description: [
        {
            title: null,
            subtitle: 'Corporate and social responsibilities play an important part in Al Hail Holding’s overall business strategy. We actively engage in socially conscious investments and programs by devoting real time and money to environmental sustainability programs, alternative energy and various social welfare initiatives to benefit employees, partners and the community at large. To this end, Al Hail Holding has established Al Hosn Research & Studies Center.',
        },
    ],
}

const zpatternDescriptionData = [
    {
        img: '/images/about-us/our-vision.jpg',
        title: 'Our Vision',
        description: [
            'To diversify the UAE economy and develop the private sector.',
        ],
    },
    {
        img: '/images/about-us/our-mission.jpg',
        title: 'Our Mission',
        description: [
            'We strive to offer unparalleled opportunities for investment through our expertise and solid reputation in the industry and provide world-class service by developing enduring business relationships and ultimately facilitating the successful implementation of a wide range of government and private sector projects.',
        ],
    },
]

const AboutUs = (props) => {
    const {strapiAboutUs, allStrapiLeaderships, strapiCheckerboard} = useStaticQuery(query);
    
    return (
        <Layout path={props.location.pathname}>
            <Seo
              title={strapiAboutUs.metaTitle}
              description={strapiAboutUs.metaDescription}
              keywords={strapiAboutUs.metaKeywords} />
            
            <HeroSmall img={`${process.env.GATSBY_STRAPI_API_URL}${strapiAboutUs.background?.url}`} strapiHomePage={strapiAboutUs} />
            <Description title1={strapiAboutUs.whatwedo_title1} title2={strapiAboutUs.whatwedo_title2} data={strapiAboutUs.what_we_dos} />
            <LeadershipMobile datas={allStrapiLeaderships}/>
            <Leadership datas={allStrapiLeaderships} />
            <ZPatternFlat datas={strapiAboutUs}/>
            <CoreValues datas={strapiAboutUs}/>
            <Description title1={strapiAboutUs.whatwedo_title3} title2={strapiAboutUs.whatwedo_title4} data={strapiAboutUs.corporate_and_responsabilities} />
            {/*<Checkerboard datas={strapiCheckerboard}/>*/}
        </Layout>
    )
}

export default AboutUs

const query = graphql`
    query {
        strapiAboutUs(locale: {eq: "en"}) {
            metaTitle
            metaDescription
            metaKeywords
            title
            subtitle
            breadcrumb {
                label
                url
            }
            whatwedo_title1
            whatwedo_title2
            what_we_dos {
                id
                Title
                Description
            }
            our_values {
                title
                description
                position
                image {
                    url
                }
            }
            core_values
            core_values_list {
                title
                description
                position
            }
            whatwedo_title3
            whatwedo_title4
            corporate_and_responsabilities {
                Description
            }
            background {
                url
            }
        }
        allStrapiLeaderships(filter: {locale: {eq: "en"}}, sort: {fields: index}) {
            edges {
                node {
                    name
                    position
                    descriptions
                    photo {
                        url
                    }
                }
            }
        }
        strapiCheckerboard {
            title
            description
            detailAddress
            AreaAddress
            poAddress
            phone
            fax
            email
            website
            image {
                url
            }
        }
    }
`;
