import React, {useEffect} from 'react'
import styled from 'styled-components'
import H2 from '/src/components/library/H2'
import Body from '/src/components/library/Body'

const ZPatternFlat = ({datas}) => {
    let reverse = true
    let sortedDatas = datas.our_values.sort(function (a, b) {
        return a.position - b.position
    })


    useEffect(() => {
        // console.log('1------1')
        // console.log(datas.our_values)
        // console.log('2------2')
        // sortedDatas = datas.our_values.sort(function(a, b) {return a.position-b.position})
    })

    return (
        <StyledZPatternFlat>
            <Wrapper>
                {/*{console.log(sortedDatas)}*/}
                {
                    sortedDatas.map((data, key) => {
                        reverse = !reverse
                        return (
                            <Category key={key} reverse={reverse}>
                                <TextArea>
                                    <TextWrapper>
                                        <H2>{data.title}</H2>
                                        <BodyWrapper>
                                            <Body>{data.description}</Body>
                                        </BodyWrapper>
                                    </TextWrapper>
                                </TextArea>
                                <Image img={`${process.env.GATSBY_STRAPI_API_URL}${data.image.url}`}/>
                            </Category>
                        )
                    })
                }
            </Wrapper>
        </StyledZPatternFlat>
    )
}

export default ZPatternFlat

const StyledZPatternFlat = styled.div`
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`

  max-width: ${({theme}) => theme.layout.xxxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  //padding: 7.5rem 0 7.5rem 0;

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding: 5rem 0rem;
    flex-direction: column-reverse;
  }
`

const Category = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({reverse}) => reverse ? 'row-reverse' : 'row'};
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    flex-direction: column-reverse;
    min-height: unset;
  }
  min-height: 50vh;
`

const TextArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    // padding: 3.2rem 3.2rem;
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 2.4rem;
  width: calc(${({theme}) => theme.layout.xxLargeScreen} / 2);
  padding: 3.2rem 2.2rem;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    padding: 3.2rem 2.2rem;
  }
`

const BodyWrapper = styled.div`
  max-width: 47rem;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    max-width: unset;
  }
`

const Image = styled.div`
  flex: 1;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    flex: unset;
    height: 40vh;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    height: 50vh;
  }
`
