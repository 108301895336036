import React from 'react'
import styled from 'styled-components'

import H3 from '/src/components/library/H3'
import Body2 from "/src/components/library/Body2";

const Checkerboard = ({ datas }) => {
    return (
        <StyledCheckerboard>
            <Wrapper>
                <Content>
                    <TextWrapper>
                        <H3>{datas.title}</H3>
                        <BodyWrapper>
                            <Body2>{datas.description}</Body2>
                        </BodyWrapper>
                        <Body2>{datas.detailAddress}</Body2>
                        <Body2>{datas.AreaAddress}</Body2>
                        <Body2>{datas.cityAddress}</Body2>
                        <Body2>{datas.poAddress}</Body2>
                        <Body2>Phone: <a href={'tel:+971 2 626 5151'}>{datas.phone}</a></Body2>
                        <Body2>Fax: <a href={'tel:+971 2 443 0020'}>{datas.fax}</a></Body2>
                        <Body2><a href={'mailto:info@alhosnuae.ae'}>{datas.email}</a></Body2>
                    <Body2><a href={'https://www.alhosnuae.ae'} target={'_blank'} rel={'noreferrer'}>{datas.website}</a></Body2>
                    </TextWrapper>
                </Content>
                <Image img={`${process.env.GATSBY_STRAPI_API_URL}${datas.image.url}`}/>
            </Wrapper>
        </StyledCheckerboard>
    )
}

export default Checkerboard

const StyledCheckerboard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem 0rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column-reverse;
  }
`

const Wrapper = styled.div`

    max-width: ${({ theme }) => theme.layout.xxxLargeScreen};
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    padding: 7.5rem 0 7.5rem 0;

    @media ${({ theme }) => theme.breakpoints.tablets_reverse} {
      padding: 5rem 0rem;
      flex-direction: column-reverse;
    }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  h3 {
    margin-bottom: 3rem;
  }

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    h3 {
      margin-bottom: 1.4rem;
    }
  }
`

const BodyWrapper = styled.div`
  margin-bottom: 3.6rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    margin-bottom: 2.4rem;
  }
`

const TextWrapper = styled.div`
  // padding-right: 0 12rem;
  // max-width: 40rem;
  padding-left: 2.4rem;
  padding-right: 8rem;
  max-width: calc(${({ theme }) => theme.layout.xxLargeScreen} / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  a {
    color: ${({ theme }) => theme.colors.link};
    &:hover {
      color: ${({ theme }) => theme.colors.link_hover};
    }
  }

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding: 2.4rem 3.2rem;
    flex-direction: column;
  }

`

const Image = styled.div`
  flex: 1;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 80vh;
  @media ${({ theme }) => theme.breakpoints.tablets_reverse} {
  margin-bottom: 2.4rem;
  min-height: 50vh;
}
`
