import React from 'react';
import styled from 'styled-components'

const H3 = ({children}) => {
  return (
    <H3Styled>
      {children}
    </H3Styled>
  );
};

export default H3;

const H3Styled = styled.h3`
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: 400;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2.4rem;
    line-height: 3rem;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-weight: 400;
  }
`
