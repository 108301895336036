import React, {useEffect} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import buttonImgBlack from '/static/images/modal-leader/close-black.svg'
import buttonImgWhite from '/static/images/modal-leader/close-white.svg'

import H4 from '/src/components/library/H4'
import Body from '/src/components/library/Body'

let customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#153A62B3',
        zIndex: 20,
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        backgroundColor: 'white',
    }
};

Modal.setAppElement('body')


const ModalLeader = ({data, modalState, setModalState}) => {
    // const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        // const handleResize = () => setWidth(window.innerWidth)
        document.body.style.height = "100%";
        document.body.style.overflowY = "hidden";
        // window.addEventListener("resize", handleResize)
        // if (width < 600)
        //     customStyles['content']['height'] = '80%';
        // else
        //     customStyles['content']['height'] = 'unset';
        // return () => {
        //     window.removeEventListener("resize", handleResize)
        // }
    });

    function closeModal() {
        setModalState(false);
        document.body.style.height = "unset";
        document.body.style.overflowY = "unset";
    }

    return (
        <ModalSafeRecreationStyle>
            <Modal
                isOpen={modalState}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={data.name}
                shouldCloseOnOverlayClick={true}
                className="modal-leader"
            >
                <CloseModal onClick={() => closeModal()}>
                    <ButtonImage/>
                </CloseModal>
                <Wrapper>
                    <Image img={`${process.env.GATSBY_STRAPI_API_URL}${data.node.photo.url}`}/>
                    <Info>
                        <TextWrapper>
                            <H4>{data.node.name}</H4>
                            <StatusWrapper>
                                <Body>{data.node.position}</Body>
                            </StatusWrapper>
                            <Distance>
                                {
                                    data.node.descriptions.map((p) => {
                                        return (
                                            <DescriptionWrapper>
                                                <Body>
                                                    {p}
                                                </Body>
                                            </DescriptionWrapper>
                                        )
                                    })
                                }
                            </Distance>
                        </TextWrapper>
                    </Info>
                </Wrapper>
            </Modal>
        </ModalSafeRecreationStyle>
    )
}

export default ModalLeader

const ModalSafeRecreationStyle = styled.div`
  position: relative;
  //-ms-overflow-style: none;
  //&::-webkit-scrollbar {
  //  width: 0;
  //  background: transparent;
  //}

  @media ${({theme}) => theme.breakpoints.mobile} {
    overflow-y: auto;
  }
  
 
`

const CloseModal = styled.button`
  cursor: pointer;
  z-index: 5;
  border: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
  background: none;
  @media ${({theme}) => theme.breakpoints.mobile} {
    top: 1.4rem;
    right: 1.4rem;
  }
`

const ButtonImage = styled.div`
  background-image: url(${buttonImgBlack});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.4rem;
  height: 2.4rem;
  // @media ${({ theme }) => theme.breakpoints.mobile} {
  //   background-image: url(${buttonImgWhite});
  // }
`

const Wrapper = styled.div`
  position: relative;
  height: 50vh;
  display: flex;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
    height: 100%;
    overflow: scroll;
  }
  //-ms-overflow-style: none;
  //
  //&::-webkit-scrollbar {
  //  width: 0;
  //  background: transparent;
  //}
`

const Image = styled.div`
  flex: .7;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @media ${({theme}) => theme.breakpoints.mobile} {
    border-top-right-radius: 1.2rem;
    border-bottom-left-radius: 0;
    flex: unset;
    min-height: 40vh;
  }
`

const Info = styled.div`
  margin-top: 5rem;
  margin-left: 5rem;
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 5rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    flex: unset;
  }
`

const TextWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 97%;
  padding-bottom: 5rem;
  //  max-width: 60rem;
  // padding-right: 10rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding-bottom: 6rem;
    padding-right: 0;
    //-ms-overflow-style: none;
    //
    //&::-webkit-scrollbar {
    //  width: 0;
    //  background: transparent;
    //}
  }
`

const Distance = styled.div`
  margin-top: .8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 0;
  }
`

const StatusWrapper = styled.div`
  p {
    opacity: .5;
  }
`

const DescriptionWrapper = styled.div`
  margin-top: 2rem;
  margin-right: 12rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-right: 0;
  }
`
