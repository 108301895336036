import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import H4 from '/src/components/library/H4'
import Body from '/src/components/library/Body'

import leftArrow from '/static/images/about-us/buttons/left.svg'
import rightArrow from '/static/images/about-us/buttons/right.svg'
import ModalLeader from "../modal-leader/ModalLeader";

const LeadershipMobile = ({datas}) => {
    const [index, setIndex] = useState(0)
    const [modalState, setModalState] = useState(false)
    const [, setTransition] = useState(0)

    useEffect(() => {
        document.getElementsByClassName('slidein').style = "@-webkit-keyframes slidein {0% {left: 100%;, webkit-transform: scaleX(0);}100% {left: 0;, webkit-transform: scaleX(1);}}"
        document.getElementsByClassName('slideout').style = "@-webkit-keyframes slideout {from {display : block;left: 0;}to {left: 100%;, webkit-transform: scaleX(1);display : none;}}"
    })

    function browseLeft() {
        setTransition(1)
        if (index === 0) {
            setIndex(datas.edges.length - 1)
            return
        }
        setIndex((index - 1) % datas.edges.length)
    }

    function browseRight() {
        setTransition(2)
        setIndex((index + 1) % (datas.edges.length))
    }


    return (
        <>
            {modalState &&
                <ModalLeader data={datas.edges[index]} modalState={modalState} setModalState={setModalState}/>
            }
            <StyledLeadershipMobile>
                <Center>
                    <H2>Executive Leadership Team</H2>
                    <LeadershipSection>
                        <ImageWrapper>
                            <Image img={`${process.env.GATSBY_STRAPI_API_URL}${datas.edges[index].node.photo.url}`}
                                   onClick={() => {
                                       setModalState(true)
                                   }}/>
                        </ImageWrapper>
                        <H4>{datas.edges[index].node.name}</H4>
                        <Body>{datas.edges[index].node.position}</Body>
                    </LeadershipSection>
                    <ButtonWrapper>
                        <ButtonDirection img={leftArrow} onClick={() => browseLeft()}/>
                        <ButtonDirection img={rightArrow} onClick={() => browseRight()}/>
                    </ButtonWrapper>
                </Center>
            </StyledLeadershipMobile>
        </>
    )
}

export default LeadershipMobile

const StyledLeadershipMobile = styled.div`
  display: flex;
  justify-content: center;
  padding: 10rem 0;

  @media ${({theme}) => theme.breakpoints.medium_reverse} {
    display: none;
  }
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: flex-start;

  h2 {
    margin-bottom: 1.4rem;
  }
`

const LeadershipSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-bottom: 4rem;

  p {
    opacity: .5;
  }
`

const ImageWrapper = styled.div`
  @keyframes fadeOutIn {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeOutIn {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  
  .animate-leaders {
    animation-name: fadeOutIn;
    animation-duration: 1s;
    animation-iteration-count: initial;
    animation-timing-function: cubic-bezier(.27,.4,.68,1.16);
  }
`

const Image = styled.div`
  border-radius: 12px;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: calc(100vw - 3.2rem);
  height: calc((100vw - 3.2rem) * 1.19);
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5.4rem;
`

const ButtonDirection = styled.div`
  cursor: pointer;
  background-image: url(${({img}) => img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 4.5rem;
  height: 2.8rem;
`
